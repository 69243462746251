import Checklist from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import Marker from "@editorjs/marker";
import NestedList from "@editorjs/nested-list";
import Paragraph from "@editorjs/paragraph";
import Table from "@editorjs/table";
import Underline from "@editorjs/underline";
import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js";
import Undo from "editorjs-undo";
import ptBr from "../pt-BR/editor";

const tools = {
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    config: {
      preserveBlank: true,
    },
  },
  header: {
    class: Header,
    config: {
      levels: [1, 2, 3],
      defaultLevel: 1,
    },
  },
  table: Table,
  delimiter: Delimiter,
  checklist: {
    class: Checklist,
    inlineToolbar: true,
  },
  list: {
    class: NestedList,
    inlineToolbar: true,
    config: {
      defaultStyle: "unordered",
    },
  },
  maker: Marker,
  underline: {
    class: Underline,
    shortcut: "Ctrl+U",
  },
};

export default class extends Controller {
  static targets = ["title", "content", "feedback"];

  static values = {
    url: String,
    content: String,
  };

  connect() {
    this.editor = new EditorJS({
      holder: this.contentTarget,
      placeholder: "Inicie seu documento aqui",
      data: this.parseContent(),
      tools: tools,
      i18n: ptBr,
      onReady: () => {
        const editor = this.editor;
        new Undo({ editor });
      },
      onChange: () => {
        this.save();
      },
    });
  }

  disconnect() {
    this.editor.destroy();
    this.element.remove();
  }

  save() {
    this.editor.save().then(async (data) => {
      const formData = new FormData();
      formData.append("document[title]", this.titleTarget.value);
      formData.append("document[content]", JSON.stringify(data));

      put(this.urlValue, {
        body: formData,
      }).then(() => {
        this.feedbackTarget.classList.remove("hidden");

        setTimeout(() => {
          this.feedbackTarget.classList.add("hidden");
        }, 5000);
      });
    });
  }

  parseContent() {
    if (this.contentValue == "") {
      return {};
    }

    return JSON.parse(this.contentValue);
  }
}
